import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import * as _ from 'lodash'
import { fieldsStore } from '../preset/fields/fields-store'

export default class PanelsDataApi {
  private biLogger
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments

  constructor(boundEditorSDK, coreApi: CoreApi, { experiments, biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  public loadSettingsPanelMainTab() {
    return Promise.resolve({
      payload: {},
      ok: true,
    })
  }

  public loadAdiPanelData({ componentRef, componentConnection }) {
    return Promise.all([
      this.coreApi.fields.getFieldsSortByXY(componentRef),
      this.coreApi.settings.getSubmitButtonLabel(componentRef),
      this.coreApi.getComponentConnection(componentRef),
      this.coreApi.settings.getMessage(componentRef),
      this.coreApi.settings.getSubmitOptionsData(componentRef, componentConnection),
      this.coreApi.premium.getPremiumRestrictions(),
      this.coreApi.settings.getEmails(componentRef, componentConnection),
    ]).then(
      ([
        fields,
        submitButtonLabel,
        {
          config: { successActionType, formName, preset, collectionId },
        },
        { text: successMessage },
        links,
        { restrictions, currentAscendPlan },
        { email },
      ]) => {
        const titleTranslationKeys = _.reduce(
          fieldsStore.allFieldsData,
          (acc, fieldPreset) => ({
            ...acc,
            [fieldPreset.fieldType]: _.get(fieldPreset, 'metadata.ADI_titleTranslationKey'),
          }),
          {}
        )

        return {
          fields,
          submitButtonLabel,
          successActionType,
          successMessage,
          links,
          restrictions,
          formName,
          preset,
          email,
          collectionId,
          currentAscendPlan,
          titleTranslationKeys,
        }
      }
    )
  }
}
